import React from "react";

import Typography from "@material-ui/core/Typography";
import { Card, CardContent, CardHeader } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box"
import { useConfirm } from 'material-ui-confirm';
import OrderContent from "./OrderContent"
// *** Redux
import { useDispatch } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux';
import { useEffect } from "react"

// *** Zopa components
import { setIsSessionFull, validateGlobalRegistration, setIsAllreadyRegistered, setregistrationError, setIsRegistrationValidated } from "./RegistrationActions"
import { getConfigurationdepositpercent } from "../../services/GlobalLists/GlobalListsActions";

// *** Styling
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        background: "linear-gradient(45deg, #F4F6F6 30%, #F2F3F4 90%)",
        padding: theme.spacing(2),
    },
    pageTitle: { color: "#6b6c6d" },
    blockCardHeaderStyle:
        theme.palette.blockCardHeaderStyle,
    blockCardHeaderIcon:
        theme.palette.blockCardHeaderIcon
}));

export default function ValidationForm(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const confirm = useConfirm();

    // *** Redux state
    const registrationError = useSelector(state => state.registrationReducer.registrationError, shallowEqual);
    const loccomputedOrderlines = useSelector(state => state.registrationReducer.computedOrderlines, shallowEqual);
    // const customerDoli = null;// useSelector(state => state.customerReducer.customer, shallowEqual);
    const isAllreadyRegistrered = useSelector(state => state.registrationReducer.isAllreadyRegistrered, shallowEqual);
    const orders = useSelector(state => state.productReducer.productFullregistered, shallowEqual);
    const isRegistrationValidated = useSelector(state => state.registrationReducer.isRegistrationValidated, shallowEqual);
    const isSessionFull = useSelector(state => state.registrationReducer.isSessionFull, shallowEqual);
    const reduxcustomer = useSelector(state => state.registrationReducer.loccustomer, shallowEqual);
    const inputsChanged = useSelector(state => state.registrationReducer.inputsChanged, shallowEqual);
    const productFullregistered = useSelector(state => state.productReducer.productFullregistered, shallowEqual);
    const registration = useSelector(state => state.registrationReducer.registration, shallowEqual);

    /**
      * If there's a registration Error, we open a dialog to show the error
      */
    useEffect(() => {
        if (registrationError !== "") {
            confirm({
                description: registrationError.message,
                title: '',
                cancellationText: "",
                confirmationButtonProps: { autoFocus: true }
            })
        }
    }, [registrationError]);

    /**
     * 
     */
    useEffect(() => {
        if (isAllreadyRegistrered === false && (registrationError === "") && (isSessionFull === false)
            && (loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0) > 0)
            && (!inputsChanged))
            dispatch(setIsRegistrationValidated(true));
        else
            dispatch(setIsRegistrationValidated(false));
    }, [loccomputedOrderlines, registrationError, isSessionFull, isAllreadyRegistrered, inputsChanged]);

    /**
     * If customerDoli change, we have to check if the user is not allready egistered to the current session 
     *, 
     */
    useEffect(() => {
        if (orders.length > 0) {
            let myRegistration = orders.filter(order => order.customer.email === reduxcustomer.email && order.statut !== '-1')
            if (myRegistration.length > 0) {
                dispatch(setIsAllreadyRegistered(true));
                dispatch(setregistrationError({ code: 600, message: "Vous êtes déjà inscrit à ce stage" }));
                // return;
            } else {
                dispatch(setIsAllreadyRegistered(false));
            }
        } else
            dispatch(setIsAllreadyRegistered(false));
        //}
    }, [orders]);

    /**
     * If isAllreadyRegistrered change and === true afficher message
     */
    useEffect(() => {
        if (isAllreadyRegistrered === true) {
            confirm({
                description: "Vous êtes déjà inscrit à ce stage",
                title: '',
                cancellationText: "",
                confirmationButtonProps: { autoFocus: true }
            })
            dispatch(setregistrationError(""));
        }
    }, [isAllreadyRegistrered]);

    useEffect(() => {
        if (productFullregistered.length > 0) {
            if (registration.product) {
                let max = registration.product.array_options.options_sta_nbmax;
                // if registration.product
                if (productFullregistered.length >= max)
                    dispatch(setIsSessionFull(true));
                else
                    dispatch(setIsSessionFull(false));
            }
        }
    }, [productFullregistered, registration.product]);






    function submitForm(event) {
        event.preventDefault();
        try {
            dispatch(validateGlobalRegistration());
        }
        catch (error) {
            confirm({
                description: error.message,
                title: 'Erreur de validation',
                cancellationText: "",
                confirmationButtonProps: { autoFocus: true }
            })
        }
    }

    return (
        <div>
            <Card style={{ margin: "10px" }} >
                <CardHeader title="3 - Calculez votre inscription" subheader="" style={{ backgroundColor: "#DFDFDB" }}>
                </CardHeader>

                <CardContent>
                    {/* {isAllreadyRegistrered && <Alert severity="error">Attention : Vous êtes déjà inscrit à ce stage</Alert>} */}

                    {!isRegistrationValidated && <Typography align="left">Veuillez cliquer sur le bouton 'Calculer votre inscription' afin de vérifier et calculer le montant de votre inscription </Typography>}
                    {isRegistrationValidated && <React.Fragment> <Typography align="left">
                        {/* //loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0) > 0 */}
                        Si vous souhaitez régler la totalité de votre inscription, le montant sera de : {new Intl.NumberFormat("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                        }).format(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0))
                        }. Si vous souhaitez régler un acompte, le montant sera de : {new Intl.NumberFormat("fr-FR", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            style: "currency",
                            currency: "EUR",
                        }).format(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0) * getConfigurationdepositpercent())}.
                    </Typography>
                    </React.Fragment>}

                    <Typography className={classes.secondaryHeading}></Typography>
                    <Grid spacing={2} item md={12} xs={12} container>
                        <Grid item md={12} xs={12}>
                            <Button onClick={submitForm} color="primary" style={{ marginTop: "20px" }} variant="outlined">Calculer l'inscription</Button>
                        </Grid>
                    </Grid>
                    <OrderContent />
                    {isRegistrationValidated && <Box ><Typography align="left" variant="caption">Votre inscription est maintenant vérifiée, vous pouvez passez à l'étape suivante. Si vous souhaitez modifier votre commande en cours, vous pouvez revenir aux étapes précédentes.
                    </Typography>
                    </Box>}

                </CardContent>
            </Card>
        </div>
    );
}

