import {

  GET_CUSTOMERCIVILITIESTABLE_BEGIN,
  GET_CUSTOMERCIVILITIESTABLE_SUCCESS,
  GET_CUSTOMERCIVILITIESTABLE_FAILURE,

  GET_PUBLIPOSTAGETABLE_BEGIN,
  GET_PUBLIPOSTAGETABLE_SUCCESS,
  GET_PUBLIPOSTAGETABLE_FAILURE,


  GET_INCOMESLEVELTABLE_BEGIN,
  GET_INCOMESLEVELTABLE_SUCCESS,
  GET_INCOMESLEVELTABLE_FAILURE,

  GET_CONFIGURATION_SUCCESS,
  GET_CONFIGURATION_FAILURE,

  GET_PAYMENTTYPES_BEGIN,
  GET_PAYMENTTYPES_SUCCESS,
  GET_PAYMENTTYPES_FAILURE,

  GET_DOLIBARRSTATUS_BEGIN,
  GET_DOLIBARRSTATUS_SUCCESS,
  GET_DOLIBARRSTATUS_FAILURE,

  GET_COUNTRIESTABLE_BEGIN,
  GET_COUNTRIESTABLE_SUCCESS,
  GET_COUNTRIESTABLE_FAILURE,

  GET_USERS_BEGIN,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,


} from "./GlobalListsActions";

const initialState = {
  civilities: [],
  publipostages: [],
  incomelevels: [],
  paymentTypes: [],
  countries: [],
  users: [],
  configuration: "",
  dolibarrstatus: {},
  loading: false,
  error: null,
};

export default function GlobalListsReducer(state = initialState, action) {
  switch (action.type) {

    //  *** Get civility table
    case GET_CUSTOMERCIVILITIESTABLE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_CUSTOMERCIVILITIESTABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        civilities:
          action.payload.civilities,
      };

    case GET_CUSTOMERCIVILITIESTABLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    //  *** Get publipostage tabkle
    case GET_PUBLIPOSTAGETABLE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };


    case GET_PUBLIPOSTAGETABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        publipostages:
          action.payload.publipostages,
      };

    case GET_PUBLIPOSTAGETABLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    //  *** Get publipostage tabkle
    case GET_INCOMESLEVELTABLE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_INCOMESLEVELTABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        incomelevels:
          action.payload.incomelevels,
      };

    case GET_INCOMESLEVELTABLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case GET_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        configuration:
          action.payload.configuration,
      };

    case GET_CONFIGURATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    // *** get payment types
    case GET_PAYMENTTYPES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_PAYMENTTYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentTypes: action.payload.paymentTypes,
      };

    case GET_PAYMENTTYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    //  *** Get Dolibarr status
    case GET_DOLIBARRSTATUS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        dolibarrstatus: {},
      };

    case GET_DOLIBARRSTATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        dolibarrstatus:
          action.payload.dolibarrstatus,
      };

    case GET_DOLIBARRSTATUS_FAILURE:
      return {
        ...state,
        loading: false,
        dolibarrstatus: action.payload.error,
        error: action.payload.error,
      };

    //  *** Get Countries
    case GET_COUNTRIESTABLE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_COUNTRIESTABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        countries:
          action.payload.countries,
      };

    case GET_COUNTRIESTABLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    //  *** Get Countries
    case GET_USERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users:
          action.payload.users,
      };

    case GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;





  }
}
