import React from "react";
// import "./CgvForm.css";


// *** Redux
import { useEffect } from "react"


// *** Styling
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
    root: {
        background: "linear-gradient(45deg, #F4F6F6 30%, #F2F3F4 90%)",
        padding: theme.spacing(2),
    },
    details: {
        display: "flex",
    },
    pageTitle: {
        color: "#6b6c6d"
    }
    ,
    blockCardHeaderStyle:
        theme.palette.blockCardHeaderStyle,

    blockCardHeaderIcon:
        theme.palette.blockCardHeaderIcon
}));

export default function CgvForm(props) {
    const classes = useStyles();
    const [content, setContent] = React.useState("");

    useEffect(function () {
        fetch('mentions.html')
            .then(response => response.text())
            .then(data => {
                console.log(data);
                setContent(data);
            });

    }, []);
    return (< div className='upload-expense' >

        <div className={classes.root}>
            {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
        </div>
    </div >
    );
};

