import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { TextField, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid"
import Autocomplete from "@material-ui/lab/Autocomplete";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem"
import Divider from "@material-ui/core/Divider"
import Moment from "react-moment";

import moment from 'moment'
// *** Redux
import { useDispatch } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux';

import { useEffect } from "react"
import { useConfirm } from 'material-ui-confirm';

// *** Zopa components
import { computeChosenCourse } from "../../services/services/OrderActions";
import { getSessionProducts, getHostingProducts, getMealsResourceProducts } from "../../services/CommonProduct/CommonProductActions";
import { setReduxinputsChanged, setregistrationError, setReduxRegistration, setIsSessionFull, setIsAllreadyRegistered, setIsRegistrationValidated } from "./RegistrationActions"
import { getProduct } from "../../services/services/ProductActions"
// import { getProductRegistered } from "../../services/services/ProductActions"
import { getMaxMealsForADayRange, getRestaurantBooking } from "../../services/services/RestaurantActions"

// *** Styling
const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});
export default function NeedsForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();

  //const [open, setOpen] = React.useState(false);
  const [product, setProduct] = React.useState("");
  const [localregistration, setlocalregistration] = React.useState(null);
  const [rerender, setRerender] = React.useState(false);
  const [leftMealsForADayValues, setleftMealsForADayValues] = React.useState([]);
  // const [isSessionFull, setSessionFull] = React.useState(false);


  // const [localError, setlocalError] = React.useState("");
  //const order = useSelector(state => state.orderReducer.order, shallowEqual);

  const registrationError = useSelector(state => state.registrationReducer.registrationError, shallowEqual);
  // *** not directly used but usefull for hosting, session and mels product
  const products = useSelector(state => state.commonProductReducer.products, shallowEqual);
  const registration = useSelector(state => state.registrationReducer.registration, shallowEqual);
  // const productregistered = useSelector(state => state.productReducer.productregistered, shallowEqual);
  const isSessionFull = useSelector(state => state.registrationReducer.isSessionFull, shallowEqual);
  const tabFullAggregatedResult = useSelector(state => state.restaurantReducer.tabFullAggregatedResult, shallowEqual);
  // const isAllreadyRegistrered = useSelector(state => state.registrationReducer.isAllreadyRegistrered, shallowEqual);
  const maxMealsForADayValues = useSelector(state => state.restaurantReducer.maxMealsForADayValues, shallowEqual);

  const hostingproducts = getHostingProducts();
  const sessionProducts = getSessionProducts();
  const mealTypeproducts = getMealsResourceProducts();

  // *** Styles
  const styles = (theme) => ({
    root: {
      background: "linear-gradient(45deg, #F4F6F6 30%, #F2F3F4 90%)",
      padding: theme.spacing(4),
    },
    details: {
      display: "flex",
    },
  });

  useEffect(function () {

    if (maxMealsForADayValues.length > 0) {
      let tempTab = new Array(4);
      tempTab[0] = new Array(maxMealsForADayValues[0].length).fill(true);
      tempTab[1] = new Array(maxMealsForADayValues[0].length).fill(true);
      tempTab[2] = new Array(maxMealsForADayValues[0].length).fill(true);
      tempTab[3] = new Array(maxMealsForADayValues[0].length).fill(true);

      for (let ligne = 1; ligne < maxMealsForADayValues.length; ++ligne) {
        for (let colonne = 1; colonne < maxMealsForADayValues[0].length; ++colonne) {
          if (tabFullAggregatedResult.length > 0 && tabFullAggregatedResult[ligne][colonne] > 0) {
            if (maxMealsForADayValues[ligne][colonne] !== null) {
              if (maxMealsForADayValues[ligne][colonne] !== 0) {
                tempTab[ligne][colonne] = maxMealsForADayValues[ligne][colonne] - tabFullAggregatedResult[ligne][colonne] > 0 ? true : false;
              } else {
                tempTab[ligne][colonne] = false;
              }
            }
          } else {
            tempTab[ligne][colonne] = maxMealsForADayValues[ligne][colonne] === 0 ? false : true;
          }
        }
      }
      setleftMealsForADayValues(tempTab);
    }
    // *** Set the meals authorize tab

  }, [maxMealsForADayValues, tabFullAggregatedResult]);

  useEffect(() => {
    if (registrationError !== "") {
      confirm({
        description: registrationError.message,
        title: '',
        cancellationText: "",
        confirmationButtonProps: { autoFocus: true }
      })
    }
  }, [maxMealsForADayValues,]);


  useEffect(() => {
    if (registrationError !== "") {
      confirm({
        description: registrationError.message,
        title: '',
        cancellationText: "",
        confirmationButtonProps: { autoFocus: true }
      })
    }
  }, [registrationError]);



  /**
  * Handle user inputs
  * @param {*} event 
  */
  function handleInputChange(event) {

    if (!event.target) {
      console.log("Handleinput change : event.target null");
      return;
    }
    if (event.target) {
      setlocalregistration((prevState) => {

        if (event.target.name === 'tabBusyParticipation')
          prevState.tabBusyParticipation[event.target.value] = !prevState.tabBusyParticipation[event.target.value];
        if (event.target.name === 'tabBusyBreakfast')
          prevState.tabBusyBreakfast[event.target.value] = !prevState.tabBusyBreakfast[event.target.value];
        if (event.target.name === 'tabBusyLunch')
          prevState.tabBusyLunch[event.target.value] = !prevState.tabBusyLunch[event.target.value];
        if (event.target.name === 'tabBusyDiner')
          prevState.tabBusyDiner[event.target.value] = !prevState.tabBusyDiner[event.target.value];
        if (event.target.name === 'tabBusyHosting') {
          if (event.target.value === '0')
            prevState.tabBusyHosting[0] = !prevState.tabBusyHosting[0];
          else
            if (parseInt(event.target.value) === prevState.tabBusyHosting.length - 2)
              prevState.tabBusyHosting[prevState.tabBusyHosting.length - 2] = !prevState.tabBusyHosting[prevState.tabBusyHosting.length - 2];
            else
              for (let i = 1; i < prevState.tabBusyHosting.length - 2; ++i)
                prevState.tabBusyHosting[i] = !prevState.tabBusyHosting[i];
        }
        if (event.target.name === 'tabBusyVeggie')
          prevState.veggie = !prevState.veggie;
        if (event.target.name === 'hostingType')
          prevState.hostingType = event.target.value;
        if ((event.target.name === "mealType"))
          prevState.mealType = event.target.value;
        return prevState
      });
    }

    dispatch(setReduxRegistration(localregistration));
    dispatch(setIsRegistrationValidated(false));
    //*** Used to force re-render  */
    setRerender(!rerender);
  }

  /**
   * The course has changed, we have to build the registration structure with this new course 
   * @param  productTemp 
   * @param {*} checkDefaultState 
   */
  function chosenCourseChanged(productTemp, checkDefaultState) {

    //try {
    if (typeof productTemp === "object" && productTemp) {
      dispatch(getProduct(productTemp.id));

      dispatch(setIsSessionFull(false));
      dispatch(setIsAllreadyRegistered(false));
      dispatch(setIsRegistrationValidated(false));

      dispatch(getMaxMealsForADayRange(moment(productTemp.array_options.options_sta_datedebut * 1000).format('YYYY-MM-DD'), moment(productTemp.array_options.options_sta_datefin * 1000).format('YYYY-MM-DD')));
      dispatch(getRestaurantBooking(moment(productTemp.array_options.options_sta_datedebut * 1000).format('YYYY-MM-DD'), moment(productTemp.array_options.options_sta_datefin * 1000).format('YYYY-MM-DD')));

      setProduct(productTemp);
      // dispatch(getProductRegistered(productTemp.id));
      let tempregistration = computeChosenCourse(productTemp, checkDefaultState);

      setlocalregistration(tempregistration);
      dispatch(setReduxRegistration(tempregistration));
      dispatch(setReduxinputsChanged(true));
    }
  }


  return (
    <React.Fragment>
      <Grid container item md={12} xs={12}
        direction="row-reverse"
        justify="center"
        alignItems="center" spacing={1} style={{ marginTop: "0px" }}>
        {isSessionFull && <Alert severity="error">Les inscriptions pour ce stage sont complètes. Vous pouvez contacter l'accueil si vous souhaitez être mis en liste d'attente.</Alert>}
        {/* {loading && <CircularProgress color="secondary" />} */}
        <Grid item md={12} xs={12}>
        </Grid>
        <Autocomplete
          id="combo-box-demo"
          fullWidth
          options={sessionProducts.filter(sessionProduct => sessionProduct.array_options.options_sta_published === '1')}
          getOptionLabel={(sessionproduct) => moment(new Date(sessionproduct.array_options.options_sta_datedebut * 1000)).format('DD/MM') + " au " + moment(new Date(sessionproduct.array_options.options_sta_datefin * 1000)).format('DD/MM') + " - " + sessionproduct.label}
          onChange={(event, newValue) => {
            chosenCourseChanged(newValue, false);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id="productId"
              name="productId"
              label="Choisir un stage"
              variant="outlined"
              fullWidth
            />
          )}
        />

        {/* // *** Start needs display */}
        {registration && !isSessionFull && registration.tabBusyLigneColonne &&
          <React.Fragment>
            <div style={{ overflow: 'auto' }}>
              <TableContainer component={Paper} >
                <Table className={classes.table} aria-label="a dense table" size="small">
                  {/* // *** Days date*/}
                  <TableHead><TableRow style={{ height: 10 }}>
                    <TableCell><Typography></Typography></TableCell>
                    {
                      registration.tabBusyLigneColonne.map((dateLine, index) => (
                        <TableCell style={{ maxWidth: '30%', }} key={index} >
                          <Typography>
                            {index === 0 ? <span>Jour avant</span> :
                              index === registration.tabBusyLigneColonne.length - 1 ? <span>Jour après</span> :
                                <Moment format="DD/MM">
                                  {dateLine}
                                </Moment>
                            }
                          </Typography>
                        </TableCell>))}
                  </TableRow>
                  </TableHead>
                  <TableBody>

                    {/* // *** Participation !registration.product.array_options.options_sta_fullattend && */}
                    {<TableRow style={{ height: 8 }}>
                      <TableCell style={{
                        minWidth: '10px', height: 'auto !important', paddingTop: 0, paddingBottom: 0
                      }}><Typography>Participation</Typography></TableCell>
                      {
                        registration.tabBusyParticipation.map((participation, index) => (
                          <TableCell key={"part" + index} style={{ minWidth: '10px', height: 'auto !important', paddingTop: 0, paddingBottom: 0 }} >
                            <Checkbox
                              checked={participation}
                              onChange={handleInputChange}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              name={"tabBusyParticipation"}
                              value={index}
                              color="default"

                              disabled={participation === null || registration.product.array_options.options_sta_fullattend === '1'}
                            />
                          </TableCell>))}
                    </TableRow>}

                    {/* // *** Breakfast */}
                    <TableRow >
                      <TableCell style={{ minWidth: '10px', height: 'auto !important', paddingTop: 0, paddingBottom: 0 }}><Typography>Petit déjeuner</Typography></TableCell>
                      {
                        registration.tabBusyBreakfast.map((breakfast, index) => (
                          <TableCell key={"break" + index} style={{ minWidth: '10px', width: 'auto !important', height: 'auto !important', paddingTop: 0, paddingBottom: 0 }} >
                            <Checkbox
                              checked={breakfast}
                              onChange={handleInputChange}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              name={"tabBusyBreakfast"}
                              value={index}
                              color="default"
                              disabled={(leftMealsForADayValues && leftMealsForADayValues[1] && leftMealsForADayValues[1][index + 1] !== true) || breakfast === null}
                            />
                          </TableCell>))}
                    </TableRow>

                    {/* // *** Lunch */}
                    <TableRow >
                      <TableCell style={{ minWidth: '10px', height: 'auto !important', paddingTop: 0, paddingBottom: 0 }}><Typography>Déjeuner</Typography></TableCell>
                      {
                        registration.tabBusyLunch.map((lunch, index) => (
                          <TableCell key={"lunc" + index} style={{ width: 'auto !important', height: 'auto !important', paddingTop: 0, paddingBottom: 0 }} >
                            <Checkbox
                              checked={lunch}
                              onChange={handleInputChange}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              name={"tabBusyLunch"}
                              value={index}
                              color="default"
                              disabled={(leftMealsForADayValues && leftMealsForADayValues[2] && leftMealsForADayValues[2][index + 1] !== true) || lunch === null}
                            />
                          </TableCell>))}
                    </TableRow>

                    {/* // *** Diner */}
                    <TableRow >
                      <TableCell style={{ minWidth: '10px', width: 'auto !important', height: 'auto !important', paddingTop: 0, paddingBottom: 0 }}><Typography>Dîner</Typography></TableCell>
                      {
                        registration.tabBusyDiner.map((diner, index) => (
                          <TableCell key={index} style={{ height: 'auto !important', paddingTop: 0, paddingBottom: 0 }} >
                            <Checkbox
                              checked={diner}
                              onChange={handleInputChange}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              name={"tabBusyDiner"}
                              value={index}
                              color="default"
                              disabled={(leftMealsForADayValues && leftMealsForADayValues[3] && leftMealsForADayValues[3][index + 1] !== true) || diner === null}
                            />
                          </TableCell>))}
                    </TableRow>

                    {/* // *** Hosting */}
                    {registration.tabBusyHosting && <TableRow >
                      <TableCell style={{ minWidth: '10px', width: 'auto !important', height: 'auto !important', paddingTop: 0, paddingBottom: 0 }}><Typography>Hébergement</Typography></TableCell>
                      {
                        registration.tabBusyHosting.map((hosting, index) => (
                          <TableCell key={index} style={{ height: 'auto !important', paddingTop: 0, paddingBottom: 0 }} >
                            <Checkbox
                              checked={hosting}
                              onChange={handleInputChange}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              name={"tabBusyHosting"}
                              value={index}
                              color="default"
                              disabled={registration.sta_hostingclosed === '1' || hosting === null}

                            />
                          </TableCell>))}
                    </TableRow>}

                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Grid container alignItems="flex-start" spacing={1} style={{ marginTop: "10px", paddingTop: "0px" }}>
              <Grid item md={6}>
                <Typography>Type d'hébergement</Typography>
                {hostingproducts &&
                  <Select style={{ minWidth: 200 }} selectedIndex={registration.hostingType && registration.hostingType}
                    name="hostingType" value={registration.hostingType}
                    onChange={handleInputChange}
                    variant="outlined"
                    disabled={registration.sta_hostingclosed === '1'}>
                    {hostingproducts.map(hostingproduct =>
                      <MenuItem value={hostingproduct.id} >{hostingproduct.label}</MenuItem>
                    )}
                  </Select>}

              </Grid>
              <Grid item md={6}>
                <Typography>Type de repas</Typography>
                {mealTypeproducts &&
                  <Select style={{ minWidth: 200 }} selectedIndex={registration.mealType && registration.mealType}
                    name="mealType" value={registration.mealType}
                    onChange={handleInputChange}
                    variant="outlined">
                    {mealTypeproducts.map(mealType =>
                      <MenuItem value={mealType.id} >{mealType.label}</MenuItem>
                    )}
                  </Select>}
              </Grid>
            </Grid>
            <Divider />

            <Grid container alignItems="flex-start" spacing={1} style={{ marginTop: "10px", marginBottom: "-10px" }}>
              <Grid item md={12}>
                <Typography variant="caption">Les cases grisées correspondent à des services non modifiables ou non accessibles.</Typography>
              </Grid>
            </Grid>
          </React.Fragment>}
      </Grid>

    </React.Fragment >
  )
};