import { store } from "../../App"
import {
  CONST_APIURLKUSALA,
} from "../../Constants.js";
import axios from "axios";
import { getUserToken } from "../../services/Login/LoginActions";
import { getConfigurationdepositpercent } from "../../services/GlobalLists/GlobalListsActions"
import { getProductFromId } from "../../services/CommonProduct/CommonProductActions"
import { computeRegistration } from "../../services/services/OrderActions";
import { getProductFullRegistered } from "../../services/services/ProductActions";
import moment from 'moment'

export const SET_REDUXCUSTOMER_SUCCESS = "SET_REDUXCUSTOMER_SUCCESS";
export const setReduxCustomer = (loccustomer) => ({
  type: SET_REDUXCUSTOMER_SUCCESS,
  payload: { loccustomer },
});

export const SET_REGISTRATION_SUCCESS = "SET_REGISTRATION_SUCCESS";
export const setReduxRegistration = (registration) => ({
  type: SET_REGISTRATION_SUCCESS,
  payload: { registration },
});

export const SET_ORDERLINES_SUCCESS = "SET_ORDERLINES_SUCCESS";
export const setReduxComputedOrderLines = (computedOrderlines) => ({
  type: SET_ORDERLINES_SUCCESS,
  payload: { computedOrderlines },
});


export const SET_REDUXPAYMENTMODE_SUCCESS = "SET_REDUXPAYMENTMODE_SUCCESS";
export const setReduxPaymentmode = (payment) => ({
  type: SET_REDUXPAYMENTMODE_SUCCESS,
  payload: { payment },
});

export const SET_SESSIONISFULL_SUCCESS = "SET_SESSIONISFULL_SUCCESS";
export const setIsSessionFull = (isSessionFull) => ({
  type: SET_SESSIONISFULL_SUCCESS,
  payload: { isSessionFull },
});


export const SET_ALLREADYREGISTERED_SUCCESS = "SET_ALLREADYREGISTERED_SUCCESS";
export const setIsAllreadyRegistered = (isAllreadyRegistrered) => ({
  type: SET_ALLREADYREGISTERED_SUCCESS,
  payload: { isAllreadyRegistrered },
});


export const SET_ISREGISTRATIONVALIDATED_SUCCESS = "SET_ISREGISTRATIONVALIDATED_SUCCESS";
export const setIsRegistrationValidated = (isRegistrationValidated) => ({
  type: SET_ISREGISTRATIONVALIDATED_SUCCESS,
  payload: { isRegistrationValidated },
});

export const SET_REGISTRATIONERROR = "SET_REGISTRATIONERROR";
export const setregistrationError = (registrationError) => ({
  type: SET_REGISTRATIONERROR,
  payload: { registrationError },
});

export const SET_INPUTSCHANGED = "SET_INPUTSCHANGED";
export const setReduxinputsChanged = (inputsChanged) => ({
  type: SET_INPUTSCHANGED,
  payload: { inputsChanged },
});

/**
 * Sync function which validates identity
 * @param {*} customer 
 * @returns true if no error otherwise throws an error
 */
export function validateIdentity(customer) {

  // const customer = store.getState().registrationReducer.customer;
  // *** Check name
  if (!customer.firstname) {
    throw Error("Veuillez saisir le prénom")
    return;
  }
  if (!customer.lastname) {
    throw Error("Veuillez saisir le nom")
  }

  if (!customer.civility) {
    throw Error("Veuillez saisir la civilité ")
  }
  if (!customer.email) {
    throw Error("Veuillez saisir votre email")
  }
  if (!customer.emailbis) {
    throw Error("Veuillez saisir la confirmation de votre email ")
  }
  if (!(customer.emailbis === customer.email)) {
    throw Error("L'email et la confirmation doivent etre les mêmes ")
  }
  if (customer.email.length > 0) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(customer.email)) {
      throw Error("votre email est invalide")
    }
  }
  if (!customer.price_level) {
    throw Error("Veuillez saisir votre niveau de revenu")
  }

  return true;
}

/**
 * Sync function that returns order lines
 * @param {*} registration 
 * @returns orderlines or throws an error
 */
export function computeConfigRegistration(registration) {
  // return (dispatch) => {
  const isSessionFull = store.getState().registrationReducer.isSessionFull;

  if (isSessionFull === true) {
    throw Error("Les inscriptions pour ce stage sont complètes. Pour être mis en liste d'attente, merci de contacter l’accueil à contact@dhagpo.org ou au 05 53 50 70 75");
  }

  if (!registration || !registration.product) {
    throw Error("Veuillez choisir un stage");
  }

  if (!registration.tabBusyParticipation.includes(true)) {
    throw Error("Veuillez sélectionner au moins un jour de participation au stage");
  }

  if (registration.tabBusyHosting && registration.tabBusyHosting.includes(true) && !registration.hostingType) {
    throw Error("Si vous demandez des hébergements, il faut choisir un type d'hébergement")
  }

  if (registration.tabBusyHosting[0] === true && registration.tabBusyHosting[1] !== true) {
    throw Error("Si vous souhaitez être hébergé le jour d'avant, il faut demander à être hébergé durant le stage")
  }
  if (registration.tabBusyHosting[registration.tabBusyHosting.length - 2] === true && registration.tabBusyHosting[1] !== true) {
    throw Error("Si vous souhaitez être hébergé le dernier jour du stage, il faut demander à être hébergé durant le stage")
  }

  if ((registration.tabBusyBreakfast.includes(true) || registration.tabBusyLunch.includes(true) || registration.tabBusyDiner.includes(true))
    && !registration.mealType) {
    throw Error("Si vous demandez des repas, il faut choisir un type de repas")
  }

  let computedOrderlines = computeRegistration(registration);
  return computedOrderlines;
  // }
}

/**
 * Put value in order lines 
 * @param {*} orderLines 
 * @returns 
 */
export function computeOrderLines(orderLines) {

  const loccustomer = store.getState().registrationReducer.loccustomer;

  let customerPriceLevel = loccustomer.price_level;

  let newOrderLines = [...orderLines];
  newOrderLines.map((orderLine, index) => {
    let product = getProductFromId(orderLine.fk_product);
    let price = product.multiprices_ttc[customerPriceLevel];
    orderLine.subprice = price;
    orderLine.total_ttc = orderLine.qty * price;
    orderLine.label = product.label
    orderLine.ref = product.ref
  })

  // *** Add subscription if requested
  if (loccustomer.subsproductId) {
    // *** Define the start and end dates
    let startDate = new Date(moment(new Date()).format('YYYY-MM-DD'));
    let end = new Date(startDate);
    end.setYear(end.getFullYear() + 1)

    newOrderLines.push(
      {

        "array_options": {
          "options_lin_datedebut": new Date(startDate) / 1000,
          "options_lin_datefin": new Date(end) / 1000,
        },
        fk_product: loccustomer.subsproductId.id,
        label: loccustomer.subsproductId.label,
        qty: 1,
        ref: loccustomer.subsproductId.ref,
        subprice: loccustomer.subsproductId.multiprices_ttc[customerPriceLevel],
        total_ttc: loccustomer.subsproductId.multiprices_ttc[customerPriceLevel]
      }
    )
  }
  return newOrderLines;
}


/****************************************************************************************** */
/**
 * Main validation function : in charge of 
 * Validating inputs, computing the registration, compute the order ttc
 * Check the existence of the customer in Dolibarr
 * Send the order to Dolibarr 
 * @returns 
 */
export function validateGlobalRegistration() {
  return (dispatch) => {

    const loccustomer = store.getState().registrationReducer.loccustomer;
    const registration = store.getState().registrationReducer.registration;

    try {
      dispatch(setIsRegistrationValidated(false));
      dispatch(setIsAllreadyRegistered(false));
      dispatch(setregistrationError(""));

      // *** manage registration and get orders lines
      let computedOrderlines = computeConfigRegistration(registration);
      dispatch(setReduxRegistration(registration));

      // *** Check identity
      validateIdentity(loccustomer);

      // *** Manage orders lines, put prices
      let valorisedOrderLines = computeOrderLines(computedOrderlines);
      dispatch(setReduxComputedOrderLines(valorisedOrderLines));

      dispatch(getProductFullRegistered(registration.product.id));
      dispatch(setReduxinputsChanged(false));

    } catch (Error) {
      dispatch(setregistrationError(Error));
    }
    return true;
  }
}

/****************************************************************************************** */
export function sendToDolibarr(captchaValue) {
  return (dispatch) => {
    // const customerDoli = store.getState().customerReducer.customer
    const loccustomer = store.getState().registrationReducer.loccustomer;
    const registration = store.getState().registrationReducer.registration;
    const loccomputedOrderlines = store.getState().registrationReducer.computedOrderlines;
    const payment = store.getState().registrationReducer.payment;


    dispatch(validateGlobalRegistration());
    dispatch(sendWebOrder(loccustomer, registration, loccomputedOrderlines, payment, captchaValue));

  }
}

/****************************************************************************************** */
export function sendWebOrder(loccustomer, registration, loccomputedOrderlines, payment, captchaValue) {

  return (dispatch) => {
    dispatch(sendWebOrderBegin());

    let acompte = Math.round(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0) * getConfigurationdepositpercent());
    let total = loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0);

    if (payment.paymentMode.includes("acompte"))
      payment.subprice = Math.round(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0) * getConfigurationdepositpercent());
    else
      payment.subprice = loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0);

    payment.captchaValue = captchaValue;
    return axios
      .post(
        CONST_APIURLKUSALA +
        "dklaccueil/weborder" +
        "?DOLAPIKEY=" +
        getUserToken(),
        {
          globalRegistration: {
            customer: loccustomer,
            registration: registration,
            orderlines: loccomputedOrderlines,
            payment: payment
          }
        }
      )
      .then((json) => {
        dispatch(sendWebOrderSuccess(json.data));
        console.log("sendWebOrderSuccess : " + json.data);
        let total_ttc = loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0);
        //*** paiement par carte */
        if (payment.paymentMode.includes('carte')) {
          let orderdata = JSON.parse(json.data);
          axios
            .post(
              CONST_APIURLKUSALA +
              "payplug/createpaiement" +
              "?DOLAPIKEY=" +
              getUserToken(),
              {
                "invoice_id": orderdata.invoiceId,
                "amount": payment.subprice,
                "customer_id": orderdata.socid,
                "title": loccustomer.civility,
                "firstname": loccustomer.firstname,
                "lastname": loccustomer.lastname,
                "email": loccustomer.email,
                "sent_by": "VAD"
              }
            )
            .then((json) => {
              window.location = json.data;
              console.log("paiement envoyé")
            })
            .catch((error) => {
              console.log("sendWebOrderFailure");
              // *** an 404 error is sent when Dolibarr didn't find invoices
              if (error.response) {
                if (error.response.status === 404) {
                  dispatch(sendWebOrderFailure(error.response.data.error));
                } else {
                  dispatch(sendWebOrderFailure(error.response.data.error));
                }
              } else {
                // *** It's an API error
                dispatch(sendWebOrderFailure(error));
              }
            });
        }

      })

      .catch((error) => {
        console.log("sendWebOrderFailure");
        // *** an 404 error is sent when Dolibarr didn't find invoices
        if (error.response) {
          if (error.response.status === 404) {
            dispatch(sendWebOrderFailure(error.response.data.error));
          } else {
            dispatch(sendWebOrderFailure(error.response.data.error));
          }
        } else {
          // *** It's an API error
          dispatch(sendWebOrderFailure(error));
        }
      });
  }
}



export const SEND_WEBORDER_BEGIN = "SEND_WEBORDER_BEGIN";
export const SEND_WEBORDER_SUCCESS = "SEND_WEBORDER_SUCCESS";
export const SEND_WEBORDER_FAILURE = "SEND_WEBORDER_FAILURE";

export const sendWebOrderBegin = () => ({
  type: SEND_WEBORDER_BEGIN,
});

export const sendWebOrderSuccess = (webOrderResult) => ({
  type: SEND_WEBORDER_SUCCESS,
  payload: { webOrderResult },
});

export const sendWebOrderFailure = (error) => ({
  type: SEND_WEBORDER_FAILURE,
  payload: { error },
});


