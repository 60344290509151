import {
  CONST_APIURLKUSALA,
  //  CONST_DOLISERVERKEYKUSALA,
} from "../../Constants.js";
import { getUserToken } from "../Login/LoginActions";
import axios from "axios";
// import { store } from "../../../App.js";
import { getRestaurantProducts } from "../CommonProduct/CommonProductActions"
import { getMealsResourceProducts } from "../CommonProduct/CommonProductActions"

import moment from 'moment'
// TODO: this component has data in its code. 

/**
 * Get the restaurant booking array
 * This code is not clean because it use a DKL definition of the restaurant offer, if the offer changes the code must be changed
 * @param {*} startDate 
 * @param {*} endDate 
 */
export function getRestaurantBooking(requesteStartdDay, requestedEndDate) {
  return (dispatch) => {
    console.log("getRestaurantBooking : ");
    dispatch(getRestaurantBookingBegin());

    // ** Load the restaurant products list id
    let restaurantProducts = getRestaurantProducts();
    let restaurantProductsList = Array.prototype.map.call(restaurantProducts, s => s.id).toString(); // "A,B,C"
    // *** Get the booking lines\

    if (restaurantProducts.length === 0) {
      dispatch(getRestaurantBookingFailure({ code: "600", message: "Stages : pas de produit actif dans la base produit, contacter l'administateur " }));
      return;
    }

    let startDate = moment(requesteStartdDay).add(-1, 'days').format("YYYY-MM-DD");
    let endDate = moment(requestedEndDate).add(+1, 'days').format("YYYY-MM-DD");
    // *** CHeck input parameters
    if (!(startDate instanceof Date || isNaN(startDate.valueOf()))) {
      dispatch(getRestaurantBookingFailure({ code: "600", message: "Restaurant : erreur de date de début de période : " }));
      return;
    }

    if (!(endDate instanceof Date || isNaN(endDate.valueOf()))) {
      dispatch(getRestaurantBookingFailure({ code: "600", message: "Restaurant : erreur de date de fin de période" }));
      return;
    }

    if (startDate > endDate) {
      dispatch(getRestaurantBookingFailure({ code: "600", message: "Restaurant : erreur date de début supérieure à la date de fin " }));
      return;
    }

    // if (((new Date(endDate) - new Date(startDate)) / 86400000) < 5) {
    //   dispatch(getRestaurantBookingFailure({ code: "600", message: "Restaurant : La période de recherche doit être de 5 jours au minimuum " }));
    //   return;

    // }
    let test = "llx_commandedet.fk_product in (" + restaurantProductsList + ") and  (not lin_datedebut is null) and (lin_datedebut >= '" + startDate + "')  and  (lin_datedebut <= '" + endDate + "' )";
    return (
      axios
        .get(
          CONST_APIURLKUSALA +
          // "dklaccueil?sqlfilters=" +
          // "llx_commandedet.fk_product in (" + restaurantProductsList + ") and  (not lin_datedebut is null) and (lin_datedebut >= '" + startDate + "')  and  (lin_datedebut <= '" + endDate + "' )" +
          "dklaccueil/orderLines" +
          "?sqlfilters=" +
          "llx_commandedet.fk_product in (" + restaurantProductsList + ") and  (not lin_datedebut is null) and (lin_datedebut >= '" + startDate + "')  and  (lin_datedebut <= '" + endDate + "' )" +

          "&DOLAPIKEY=" +
          getUserToken(),
          {
            params: {
              limit: 1000,
            },
          }
        )
        .then((json) => {

          // *** Get the meal types
          if (json.data) {
            const mealTypeproducts = getMealsResourceProducts();
            mealTypeproducts.push({ label: "Repas Inconnu" });
            // *** Filter draft and cancelled orders 
            let filteredResults = json.data.filter((orderline) => orderline.order.statut > '0');

            // *** Sort the result by date ascending
            let sortedResults = filteredResults.sort((a, b) => a.array_options.options_lin_datedebut - b.array_options.options_lin_datedebut);

            // // *** Initailise vars
            let dateDebut = new Date(startDate);
            let dateFin = new Date(endDate);
            let nbJour = ((dateFin - dateDebut) / 86400000) + 1;

            // *** Build result Array
            const tabAllMealsResult = [];
            var i;
            for (i = 0; i <= mealTypeproducts.length; ++i) {
              let tabTempMealsResult = new Array(3).fill(0).map(() => new Array(nbJour).fill(0));
              tabAllMealsResult.push(tabTempMealsResult);
            }
            // let tabTempResult = new Array(6).fill(0).map(() => new Array(nbJour).fill(0));

            // // *** iterate booking lines and Fill table with 
            sortedResults.forEach(function (item, index, array) {
              var dateDebutItem = new Date(item.array_options.options_lin_datedebut * 1000);
              let diffInTime = dateDebutItem.getTime() - dateDebut.getTime();
              let diffInDay = diffInTime / (1000 * 3600 * 24);
              let startOffsetDays = Math.round(diffInDay);

              // ** Define the current type of meal
              let currentMealIndex = mealTypeproducts.findIndex((element) => element.id === item.array_options.options_lin_room);
              if (currentMealIndex === -1)
                currentMealIndex = mealTypeproducts.length - 1;

              // compute the product ref 
              let refItem = item.ref.substring(0, item.ref.lastIndexOf("_"));

              // let codeItem = item.ref;
              if (refItem.endsWith("PMS")) {
                ++tabAllMealsResult[currentMealIndex][0][startOffsetDays];
                ++tabAllMealsResult[currentMealIndex][1][startOffsetDays];
                ++tabAllMealsResult[currentMealIndex][2][startOffsetDays];

              } else if (refItem.endsWith("PM")) {
                ++tabAllMealsResult[currentMealIndex][0][startOffsetDays];
                ++tabAllMealsResult[currentMealIndex][1][startOffsetDays];
              }
              else if (refItem.endsWith("PS")) {
                ++tabAllMealsResult[currentMealIndex][0][startOffsetDays];
                ++tabAllMealsResult[currentMealIndex][2][startOffsetDays];
              }
              else if (refItem.endsWith("MS")) {
                ++tabAllMealsResult[currentMealIndex][1][startOffsetDays];
                ++tabAllMealsResult[currentMealIndex][2][startOffsetDays];
              }
              else if (refItem.endsWith("P")) {
                ++tabAllMealsResult[currentMealIndex][0][startOffsetDays];
              }
              else if (refItem.endsWith("M")) {
                ++tabAllMealsResult[currentMealIndex][1][startOffsetDays];

              }
              else if (refItem.endsWith("S")) {
                ++tabAllMealsResult[currentMealIndex][2][startOffsetDays];
              } else {
                console.log("Code produit non PMS");
              }
            });


            // *** Build output table
            let tabLabels = [];
            tabLabels.push("Matin");
            tabLabels.push("Midi");
            tabLabels.push("Soir");

            let tabFullResult = [];

            // *** Insert label line
            let tabTitleLine = [];
            tabTitleLine.push("Title");
            for (let ligne = 0; ligne < nbJour; ++ligne) {
              var datedebutemp = new Date(dateDebut);
              var tempDate = new Date(datedebutemp.setDate(dateDebut.getDate() + ligne));
              tabTitleLine.push(tempDate.getDate() + "/" + (tempDate.getMonth() + 1));
            }
            tabFullResult.push(tabTitleLine);

            // *** Insert results
            for (let mealTypeIndex = 0; mealTypeIndex < mealTypeproducts.length; ++mealTypeIndex) {
              // *** Insert labels

              for (let ligne = 0; ligne < 3; ++ligne) {
                let tabDataLine = [];
                tabDataLine.push(mealTypeproducts[mealTypeIndex].label + " - " + tabLabels[ligne]);
                for (let colonne = 0; colonne < nbJour + 1; ++colonne) {
                  if (parseInt(tabAllMealsResult[mealTypeIndex][ligne][colonne]) > 0)
                    tabDataLine.push(tabAllMealsResult[mealTypeIndex][ligne][colonne]);
                  else
                    tabDataLine.push(0);

                }
                tabFullResult.push(tabDataLine);
              }
            }

            // *** Build the aggregated table
            let tabFullAggregatedResult = [];

            // *** Insert label line
            let tabAggregatedTitleLine = [];
            tabAggregatedTitleLine.push("Title");
            for (let ligne = 0; ligne < nbJour; ++ligne) {
              var datedebutemp = new Date(dateDebut);
              var tempDate = new Date(datedebutemp.setDate(dateDebut.getDate() + ligne));
              tabAggregatedTitleLine.push(tempDate.getDate() + "/" + (tempDate.getMonth() + 1));
            }
            tabFullAggregatedResult.push(tabAggregatedTitleLine);
            // *** Insert results


            for (let ligne = 0; ligne < 3; ++ligne) {
              let tabAggregatedDataLine = [];
              tabAggregatedDataLine.push(tabLabels[ligne])
              for (let mealTypeIndex = 0; mealTypeIndex < mealTypeproducts.length; ++mealTypeIndex) {
                if (mealTypeIndex === 0) {
                  // tabAggregatedDataLine = [...tabAllMealsResult[mealTypeIndex][0]];
                  for (let col = 0; col < nbJour; ++col) {
                    tabAggregatedDataLine[col + 1] = tabAllMealsResult[mealTypeIndex][ligne][col];
                  }


                } else {
                  for (let col = 0; col < nbJour; ++col) {
                    tabAggregatedDataLine[col + 1] += tabAllMealsResult[mealTypeIndex][ligne][col];
                  }
                }
              }

              tabFullAggregatedResult.push(tabAggregatedDataLine);
            }
            // *** Send to reducer
            dispatch(getRestaurantBookingSuccess(sortedResults, tabFullResult, tabFullAggregatedResult));

            return tabAllMealsResult;
          } else
            return [];
          // return json;
        })
        .catch((error) => {
          console.log("getRestaurantBookingFailure");
          // *** an 404 error is sent when Dolibarr didn't find invoices
          if (error.response) {
            // *** It's a Dolibarr error
            if (error.response.status === 404)
              dispatch(getRestaurantBookingFailure({ code: 600, message: "Pas de commande dans la période " }));
            else
              dispatch(getRestaurantBookingFailure(error.response.data.error));
          } else {
            // *** It's an API error
            dispatch(getRestaurantBookingFailure(error));
          }
        })
    );
  };
}

export const GET_RESTAURANTBOOKING_BEGIN = "GET_RESTAURANTBOOKING_BEGIN";
export const GET_RESTAURANTBOOKING_SUCCESS = "GET_RESTAURANTBOOKING_SUCCESS";
export const GET_RESTAURANTBOOKING_FAILURE = "GET_RESTAURANTBOOKING_FAILURE";

export const getRestaurantBookingBegin = () => ({
  type: GET_RESTAURANTBOOKING_BEGIN,
});

export const getRestaurantBookingSuccess = (bookingLines, tabFullResult, tabFullAggregatedResult) => ({
  type: GET_RESTAURANTBOOKING_SUCCESS,
  payload: { bookingLines, tabFullResult, tabFullAggregatedResult },
});

export const getRestaurantBookingFailure = (error) => ({
  type: GET_RESTAURANTBOOKING_FAILURE,
  payload: { error },
});


export const SET_RESTAURANTBOOKING_INIT = "SET_RESTAURANTBOOKING_INIT";
export const setRestaurantBookingInit = () => ({
  type: SET_RESTAURANTBOOKING_INIT,
});

// **************************************************
/**
 * Update the price level of a customer
 * this function use a dklaccueil API because Dolibarr have not this function in the API
 * @param {*} customerId 
 * @param {*} price_level 
 */
export function getMaxMealsForADayRange(requesteStartdDay, requestedEndDay) {
  return (dispatch) => {
    console.log("getMaxMealsForADay  ");
    dispatch(getMaxMealsForADayBegin());
    let startDate = moment(requesteStartdDay).add(-1, 'days').format("YYYY-MM-DD");
    let endDate = moment(requestedEndDay).add(+1, 'days').format("YYYY-MM-DD");
    return axios
      .get(
        CONST_APIURLKUSALA +
        "dklaccueil/agendaevents" +
        "?DOLAPIKEY=" +
        getUserToken() +
        "&sqlfilters=t.datep>='" + startDate + "' and t.datep<='" + endDate + "'"
        + " and code ='AC_ZOPAMEALS'"
      ) // 
      .then((json) => {
        console.log(getMaxMealsForADaySuccess);
        // let startDate2 = moment(requesteStartdDay * 1000).format("YYYY-MM-DD");
        // let endDate2 = moment(requestedEndDay * 1000).format("YYYY-MM-DD");

        let dateDebut = new Date(startDate);
        // dateDebut.setDate(dateDebut.getDate() - 1);
        let dateFin = new Date(endDate);
        let nbJour = ((dateFin - dateDebut) / 86400000) + 1;
        let maxMealsTab = [];

        let tabTitleLine = [];
        tabTitleLine.push("Title");
        for (let ligne = 0; ligne < nbJour; ++ligne) {
          var datedebutemp = new Date(dateDebut);
          var tempDate = new Date(datedebutemp.setDate(dateDebut.getDate() + ligne));
          tabTitleLine.push(tempDate.getDate() + "/" + (tempDate.getMonth() + 1));
        }
        maxMealsTab.push(tabTitleLine);

        let tabLabels = [];
        tabLabels.push("Matin");
        tabLabels.push("Midi");
        tabLabels.push("Soir");
        for (let ligne = 0; ligne < 3; ++ligne) {
          //let tabTempMealsResult = new Array(3).fill('').map(() => new Array(nbJour).fill(''));
          let tabMaxMealsDataLine = new Array(nbJour + 1).fill(null);
          tabMaxMealsDataLine[0] = tabLabels[ligne];
          maxMealsTab.push(tabMaxMealsDataLine)
        }

        json.data.forEach(function (item) {
          // *** Get the item date
          // let itemdate = moment(item.datep * 1000).format("DD/MM");
          let itemdateDate = new Date(item.datep * 1000);
          let itemdate = itemdateDate.getDate() + "/" + (itemdateDate.getMonth() + 1);

          // *** Search for the index in the result table
          let raking = maxMealsTab[0].indexOf(itemdate);
          if (raking >= 0) {
            let jsonMax = JSON.parse(item.note_private);
            maxMealsTab[1][raking] = jsonMax.P;
            maxMealsTab[2][raking] = jsonMax.M;
            maxMealsTab[3][raking] = jsonMax.S;
          }
        });

        dispatch(getMaxMealsForADaySuccess(maxMealsTab));
      })
      .catch((error) => {
        console.log("getMaxMealsForADayFailure");

        // *** 
        if (error.response) {
          // *** It's a Dolibarr error
          if (error.response && error.response.status === 404) {
            let startDate = moment(requesteStartdDay).add(-1, 'days').format("YYYY-MM-DD");
            let dateDebut = new Date(startDate);
            let dateFin = new Date(requestedEndDay);
            let nbJour = ((dateFin - dateDebut) / 86400000) + 1;
            let maxMealsTab = [];

            let tabTitleLine = [];
            tabTitleLine.push("Title");
            for (let ligne = 0; ligne < nbJour; ++ligne) {
              var datedebutemp = new Date(dateDebut);
              var tempDate = new Date(datedebutemp.setDate(dateDebut.getDate() + ligne));
              tabTitleLine.push(tempDate.getDate() + "/" + (tempDate.getMonth() + 1));
            }
            maxMealsTab.push(tabTitleLine);

            let tabLabels = [];
            tabLabels.push("Matin");
            tabLabels.push("Midi");
            tabLabels.push("Soir");
            for (let ligne = 0; ligne < 3; ++ligne) {
              //let tabTempMealsResult = new Array(3).fill('').map(() => new Array(nbJour).fill(''));
              let tabMaxMealsDataLine = new Array(nbJour + 1).fill(null);
              tabMaxMealsDataLine[0] = tabLabels[ligne];
              maxMealsTab.push(tabMaxMealsDataLine)
            }

            dispatch(getMaxMealsForADaySuccess(maxMealsTab));
          }

          else
            dispatch(getMaxMealsForADayFailure(error.response.data.error));
        } else {
          // *** It's an API error
          dispatch(getMaxMealsForADayFailure(error));
        }
      });
  };
}

export const PUT_MAXMEALSFORADAY_BEGIN = "PUT_MAXMEALSFORADAY_BEGIN";
export const PUT_MAXMEALSFORADAY_FAILURE = "PUT_MAXMEALSFORADAY_FAILURE";
export const PUT_MAXMEALSFORADAY_SUCCESS = "PUT_MAXMEALSFORADAY_SUCCESS";


export const getMaxMealsForADayBegin = () => ({
  type: PUT_MAXMEALSFORADAY_BEGIN,
});

export const getMaxMealsForADaySuccess = (maxMealsForADayValues) => ({
  type: PUT_MAXMEALSFORADAY_SUCCESS,
  payload: { maxMealsForADayValues },
});

export const getMaxMealsForADayFailure = (error) => ({
  type: PUT_MAXMEALSFORADAY_FAILURE,
  payload: { error },
});