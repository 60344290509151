import {
  SET_REDUXCUSTOMER_SUCCESS,
  SET_REGISTRATION_SUCCESS,
  SET_ORDERLINES_SUCCESS,
  SET_SESSIONISFULL_SUCCESS,
  SET_ALLREADYREGISTERED_SUCCESS,
  SET_REGISTRATIONERROR,
  SET_ISREGISTRATIONVALIDATED_SUCCESS,
  SEND_WEBORDER_BEGIN,
  SET_INPUTSCHANGED,
  SEND_WEBORDER_SUCCESS,
  SEND_WEBORDER_FAILURE,
  SET_REDUXPAYMENTMODE_SUCCESS,

} from "./RegistrationActions";

const initialState = {
  registration: {}, // the registration request filled by the user
  computedOrderlines: [],  // The order lines of the order to be created
  loccustomer: {
    civility: '', firstname: '', lastname: '', email: '', emailbis: '', price_level: '', subsproductId: {}
  },  // The user details filled by the user
  payment: { paymentMode: "", subprice: 0, desc: "" },
  isAllreadyRegistrered: false,
  isSessionFull: false,
  loading: false,
  registrationError: "",
  isRegistrationValidated: false,
  isRegistrationSent: false,
  // loading: false,
  webOrderResult: null,
  message: "",
  error: null,
  sendingError: null,
  inputsChanged: false
};

export default function RegistrationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_REDUXCUSTOMER_SUCCESS:
      // let test = state;
      return {
        ...state,
        loading: false,
        loccustomer: action.payload.loccustomer
      };
    case SET_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        registration: action.payload.registration
      };
    case SET_ORDERLINES_SUCCESS:
      return {
        ...state,
        loading: false,
        computedOrderlines: action.payload.computedOrderlines
      };
    case SET_REDUXPAYMENTMODE_SUCCESS:
      return {
        ...state,
        loading: false,
        payment: action.payload.payment
      };

    case SET_SESSIONISFULL_SUCCESS:
      return {
        ...state,
        loading: false,
        isSessionFull: action.payload.isSessionFull
      };
    case SET_ALLREADYREGISTERED_SUCCESS:
      return {
        ...state,
        loading: false,
        isAllreadyRegistrered: action.payload.isAllreadyRegistrered
      };

    case SET_REGISTRATIONERROR:
      return {
        ...state,
        loading: false,
        registrationError: action.payload.registrationError
      };


    case SET_ISREGISTRATIONVALIDATED_SUCCESS:
      return {
        ...state,
        loading: false,
        isRegistrationValidated: action.payload.isRegistrationValidated
      };

    case SET_INPUTSCHANGED:
      return {
        ...state,
        loading: false,
        inputsChanged: action.payload.inputsChanged
      };



    // *** Get Order
    case SEND_WEBORDER_BEGIN:
      return {
        ...state,
        loadingOrder: true,
        sendingError: null,
      };

    case SEND_WEBORDER_SUCCESS:
      return {
        ...state,
        loadingOrder: false,
        webOrderResult: action.payload.webOrderResult
      };

    case SEND_WEBORDER_FAILURE:
      return {
        ...state,
        loadingOrder: false,
        sendingError: action.payload.error,
      };



    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
