import React from "react";

import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import { useConfirm } from 'material-ui-confirm';
import Alert from "@material-ui/lab/Alert"
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Radio from "@material-ui/core/Radio"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import Checkbox from "@material-ui/core/Checkbox"
import Link from "@material-ui/core/Link"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Divider from "@material-ui/core/Divider"
import ReCAPTCHA from "react-google-recaptcha";
import PayplugReturnOk from "./PayplugReturnNok"
// *** Redux
import { useDispatch } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux';
import { useState } from "react"
import { useEffect } from "react"

// *** Zopa components
import { sendToDolibarr, setReduxComputedOrderLines } from "./RegistrationActions"
import { setReduxPaymentmode } from "./RegistrationActions"
import { getConfigurationdepositpercent } from "../../services/GlobalLists/GlobalListsActions";
// *** Styling
import clsx from "clsx";
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
    root: {
        background: "linear-gradient(45deg, #F4F6F6 30%, #F2F3F4 90%)",
        padding: theme.spacing(2),
    },
    pageTitle: { color: "#6b6c6d" },
    blockCardHeaderStyle:
        theme.palette.blockCardHeaderStyle,
    blockCardHeaderIcon:
        theme.palette.blockCardHeaderIcon
}));

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .1)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);



export default function SendForm({ location }) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const confirm = useConfirm();

    // *** Redux state

    // const error = useSelector(state => state.customerReducer.error, shallowEqual);
    const loadingOrder = useSelector(state => state.registrationReducer.loadingOrder, shallowEqual);
    const loccomputedOrderlines = useSelector(state => state.registrationReducer.computedOrderlines, shallowEqual);
    const configuration = useSelector(state => state.globalListsReducer.configuration, shallowEqual);
    const registration = useSelector(state => state.registrationReducer.registration, shallowEqual);

    const isRegistrationValidated = useSelector(state => state.registrationReducer.isRegistrationValidated, shallowEqual);
    const payment = useSelector(state => state.registrationReducer.payment, shallowEqual);
    const [captchaopen, setcaptchaopen] = React.useState(false);

    const webOrderResult = useSelector(state => state.registrationReducer.webOrderResult, shallowEqual);
    // const createdOrder = 

    const [createdOrder, setcreatedOrder] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [paymentChoice, setpaymentChoice] = useState("");
    const [cgvvalidated, setcgvvalidated] = useState(false);
    const [isHostingRequested, setisHostingRequested] = useState(0);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        if (webOrderResult) {
            let jsonreturnedData = JSON.parse(webOrderResult);
            setcreatedOrder(jsonreturnedData && jsonreturnedData.orderId > 0 ? true : false);

            if (!paymentChoice.includes('carte'))
                window.location.assign("/?retour=order")

            //  {
            //     confirm({
            //         description: "Votre demande d'inscription a bien été envoyée à Dhagpo Kagyu Ling ",
            //         title: "Envoi de la demande d'inscription",
            //         cancellationText: "",
            //         confirmationButtonProps: { autoFocus: true }
            //     })
            // }
        }
    }, [webOrderResult]);


    useEffect(() => {
        if (registration && registration.tabBusyHosting) {
            let isHostingRequesteda = registration.tabBusyHosting.reduce((a, b) => a + (b === true ? 1 : 0), 0)
            setisHostingRequested(isHostingRequesteda > 0);
        }
    }, [isRegistrationValidated]);



    function handlePaymentChoiceChange(event) {
        let toto = 2;
        dispatch(setReduxPaymentmode({ ...payment, paymentMode: event.target.value }));
        setpaymentChoice(event.target.value);

    }
    //   function handleInputChange(event) {
    // if (event.target.name === "zip") setzip(event.target.value);
    // if (event.target.name === "phone") setphone(event.target.value);
    // if (event.target.name === "address") setaddress(event.target.value);
    // if (event.target.name === "town") settown(event.target.value);
    // if (event.target.name === 'country_id') setcountry_id(event.target.value);
    // }
    /***
     * CGV validation
     */
    function handleBoxChange(event) {
        if (event.target) {
            if (event.target.name === 'cgv')
                setcgvvalidated(!cgvvalidated);
        }
    }
    function submitFormhostingValidation(event) {
        dispatch(setReduxPaymentmode({ ...payment, paymentMode: 'validationHosting' }));
        submitForm(event);
    }

    function submitForm(event) {
        setcaptchaopen(true);
        event.preventDefault();
    }

    // function handleRegistrationSubmit(event) {

    //     try {
    //         dispatch(sendToDolibarr());
    //     } catch (error) {
    //         confirm({
    //             description: error.message,
    //             title: 'Erreur de configuration',
    //             cancellationText: "",
    //             confirmationButtonProps: { autoFocus: true }
    //         })
    //     }

    // }

    function onCaptchaChange(value) {
        //  setReduxPaymentmode({ ...payment, captchaValue: value });
        setcaptchaopen(false);
        setcreatedOrder(true);
        try {
            dispatch(sendToDolibarr(value));
        } catch (error) {
            confirm({
                description: error.message,
                title: 'Erreur de configuration',
                cancellationText: "",
                confirmationButtonProps: { autoFocus: true }
            })
        }
    }
    function handleCaptchaCancel() {
        setcaptchaopen(false);
    }

    /**
     * 
     */
    function refreshPage() {
        window.location.assign("/");
    }

    return (
        <div>
            <Card style={{ margin: "10px" }} >
                <CardHeader title="4 - Votre inscription est prête à être envoyée" subheader="" style={{ backgroundColor: "#DFDFDB" }}>
                </CardHeader>

                <CardContent>
                    <Grid spacing={2} item md={12} xs={12} container>
                        <Grid item md={12} xs={12}>
                            <FormControlLabel
                                disabled={!isRegistrationValidated}
                                control={<Checkbox color="action" checked={cgvvalidated} onChange={handleBoxChange} name="cgv" />}
                                label="J'ai lu et j'accepte les conditions générales de vente. "
                            />
                        </Grid>
                    </Grid>

                    <Typography><Link href="#cgvanchor">Consulter les conditions générales de vente</Link> </Typography>
                    {/* {createdOrder && <Alert severity="success">Votre commande a été créée et envoyée à Dhagpo  </Alert>} */}
                    {/* {localError && <Alert severity="error">Erreur : {localError}</Alert>} */}
                    {loadingOrder && <CircularProgress color="secondary" />}
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} disabled={!isRegistrationValidated || !cgvvalidated || !isHostingRequested}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header">
                            <Typography className={classes.heading}>Si vous avez demandé un hébergement, envoyez votre demande pour validation, cliquez ici</Typography>
                            <Typography className={classes.secondaryHeading}></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid spacing={2} item md={12} xs={12} container>
                                <Typography align="left">
                                    Votre demande sera transmise à Dhagpo Kagyu Ling qui, après validation vous proposera, à votre convenance, de régler un acompte ou la totalité de votre inscription.
                                </Typography>
                                <Grid item md={12} xs={12}>
                                    <Button onClick={submitFormhostingValidation} disabled={isRegistrationValidated !== true || (createdOrder && createdOrder > 0) ? true : false || !cgvvalidated} color="primary" style={{ marginTop: "20px" }} variant="outlined" >Envoyer l'inscription</Button>
                                    {/* <Button onClick={refreshPage} disabled={!(createdOrder && createdOrder > 0) ? true : false} color="primary" style={{ marginTop: "20px", marginLeft: "20px" }} variant="outlined" >Autre inscription</Button> */}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} disabled={!isRegistrationValidated || !cgvvalidated || isHostingRequested || isHostingRequested}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography className={classes.heading}>Si vous souhaitez régler la totalité de votre inscription, cliquer ici</Typography>
                            <Typography className={classes.secondaryHeading}>

                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Grid spacing={1} item md={12} xs={12} container
                                direction="row"
                                justify="space-evenly"
                                alignItems="center">
                                <Grid spacing={2} item md={12} xs={12} container >
                                    < Grid item md={4} xs={12}>
                                        <Typography align="left">Vous souhaitez régler par : </Typography>
                                        <RadioGroup label="" name="gender1" value={paymentChoice} onChange={handlePaymentChoiceChange}>
                                            {/* <FormControlLabel value="totalcarte" control={<Radio />} label="Carte bancaire" /> */}
                                            <FormControlLabel value="totalcheque" control={<Radio />} label="Chèque" />
                                            <FormControlLabel value="totalvirement" control={<Radio />} label="Virement" />
                                        </RadioGroup>
                                    </Grid>
                                    < Grid item md={8} xs={12} >
                                        {paymentChoice.includes('carte') && <Grid container spacing={1} md={12}>
                                            <Typography align="left">Cliquez sur Envoyer l'inscription, vous serez redirigé vers le formulaire de paiement.</Typography>

                                            <Grid item md={12} xs={12}>
                                                <Button onClick={submitForm} disabled={isRegistrationValidated !== true || (createdOrder && createdOrder > 0) ? true : false || !cgvvalidated} color="primary" style={{ marginTop: "20px" }} variant="outlined" >Envoyer l'inscription</Button>
                                                {/* <Button onClick={refreshPage} disabled={!(createdOrder && createdOrder > 0) ? true : false} color="primary" style={{ marginTop: "20px", marginLeft: "20px" }} variant="outlined" >Autre inscription</Button> */}
                                            </Grid>
                                        </Grid>}
                                        {paymentChoice.includes('cheque') && <Grid container spacing={2} md={12}>
                                            <Grid item md={12} xs={12}>
                                                <Typography align="left">
                                                    <ul>
                                                        <li>Envoyez votre chèque d'un montant de {new Intl.NumberFormat("fr-FR", {
                                                            style: "currency",
                                                            currency: "EUR",
                                                        }).format(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0))
                                                        } en respectant ce montant précis, à l'ordre de 'KDC' à l'adresse suivante : Dhagpo Kagyu Ling, Landrevie, 24290 - Saint-Léon-sur-Vézère.
                                                        </li>
                                                        <li>Précisez : votre nom et votre prénom, votre adresse, les dates et le nom du stage.
                                                        </li><li>Vous devez envoyer votre chèque sous 15 jours à compter d’aujourd’hui. Au-delà, nous annulerons votre réservation.
                                                        </li>
                                                    </ul>
                                                    Nous vous confirmerons votre inscription par email.<br />
                                                    Cliquez sur le bouton 'Envoyer l'inscription'.
                                                </Typography>
                                                <Button onClick={submitForm} disabled={isRegistrationValidated !== true || (createdOrder && createdOrder > 0) ? true : false} color="primary" style={{ marginTop: "20px" }} variant="outlined" >Envoyer l'inscription</Button>
                                                {/* <Button onClick={refreshPage} color="primary" disabled={!(createdOrder && createdOrder > 0) ? true : false} style={{ marginTop: "20px", marginLeft: "20px" }} variant="outlined" >Autre inscription</Button> */}

                                            </Grid>
                                        </Grid>}
                                        {paymentChoice.includes('virement') && <Grid container spacing={2} md={12}>
                                            <Grid item md={12} xs={12}>
                                                <Typography align="left">
                                                    <ul>
                                                        <li>Envoyez votre virement d'un montant de {new Intl.NumberFormat("fr-FR", {
                                                            style: "currency",
                                                            currency: "EUR",
                                                        }).format(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0))
                                                        } en respectant ce montant précis, à KDC, IBAN : FR76 1240 6000 0900 1331 1240 430.
                                                        </li>
                                                        <li>Précisez : votre nom et votre prénom, les dates et le nom du stage.
                                                        </li>
                                                        <li>Vous devez effectuer votre virement sous 15 jours à compter d’aujourd’hui. Au-delà, nous annulerons votre réservation.
                                                        </li>
                                                    </ul>
                                                    Nous vous confirmerons votre inscription par email.<br />
                                                    Cliquez sur le bouton 'Envoyer l'inscription'.
                                                </Typography>
                                                <Button onClick={submitForm} disabled={isRegistrationValidated !== true || (createdOrder && createdOrder > 0) ? true : false || !cgvvalidated} color="primary" style={{ marginTop: "20px" }} variant="outlined" >Envoyer l'inscription</Button>
                                                {/* <Button onClick={refreshPage} disabled={!(createdOrder && createdOrder > 0) ? true : false} color="primary" style={{ marginTop: "20px", marginLeft: "20px" }} variant="outlined" >Autre inscription</Button> */}
                                            </Grid>
                                        </Grid>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} disabled={!isRegistrationValidated || !cgvvalidated || isHostingRequested}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header">
                            <Typography className={classes.heading}>Si vous souhaitez régler uniquement un acompte pour votre inscription, cliquez ici</Typography>
                            <Typography className={classes.secondaryHeading}>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid spacing={1} item md={12} xs={12} container
                                direction="row"
                                justify="space-evenly"
                                alignItems="center">
                                <Grid spacing={2} item md={12} xs={12} container >
                                    < Grid item md={4} xs={12}>
                                        <Typography align="left">Vous souhaitez régler par : </Typography>
                                        <RadioGroup label="" name="gender1" value={paymentChoice} onChange={handlePaymentChoiceChange}>
                                            {/* <FormControlLabel value="acomptecarte" control={<Radio />} label="Carte bancaire" /> */}
                                            <FormControlLabel value="acomptecheque" control={<Radio />} label="Chèque" />
                                            <FormControlLabel value="acomptevirement" control={<Radio />} label="Virement" />
                                        </RadioGroup>
                                    </Grid>
                                    < Grid item md={8} xs={12} >
                                        {paymentChoice.includes('carte') && <Grid container spacing={1} md={12}>
                                            <Typography align="left">Cliquez sur Envoyer l'inscription, vous serez redirigé vers le formulaire de paiement.</Typography>


                                            <Grid item md={12} xs={12}>
                                                <Button onClick={submitForm} disabled={isRegistrationValidated !== true || (createdOrder && createdOrder > 0) ? true : false || !cgvvalidated} color="primary" style={{ marginTop: "20px" }} variant="outlined" >Envoyer l'inscription</Button>
                                                {/* <Button onClick={refreshPage} disabled={!(createdOrder && createdOrder > 0) ? true : false} color="primary" style={{ marginTop: "20px", marginLeft: "20px" }} variant="outlined" >Autre inscription</Button> */}
                                            </Grid>
                                        </Grid>
                                        }

                                        {paymentChoice.includes('cheque') && <Grid container spacing={2} md={12}>
                                            <Grid item md={12} xs={12}>
                                                <Typography align="left">
                                                    <ul>
                                                        <li>Envoyez votre chèque d'un montant de {new Intl.NumberFormat("fr-FR", {

                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0,
                                                            style: "currency",
                                                            currency: "EUR",
                                                        }).format(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0) * getConfigurationdepositpercent())} en respectant ce montant précis, à l'ordre de 'KDC' à l'adresse suivante : Dhagpo Kagyu Ling, Landrevie, 24290 - Saint-Léon-sur-Vézère.
                                                        </li>
                                                        <li>Précisez : votre nom et votre prénom, votre adresse, les dates et le nom du stage.
                                                        </li><li>Vous devez envoyer votre chèque so}us 15 jours à compter d’aujourd’hui. Au-delà, nous annulerons votre réservation.
                                                        </li>
                                                    </ul>
                                                    Nous vous confirmerons votre inscription par email.<br />
                                                    Cliquez sur le bouton 'Envoyer l'inscription'.
                                                </Typography>
                                                <Button onClick={submitForm} disabled={isRegistrationValidated !== true || (createdOrder && createdOrder > 0) ? true : false || !cgvvalidated} color="primary" style={{ marginTop: "20px" }} variant="outlined" >Envoyer l'inscription</Button>
                                                {/* <Button onClick={refreshPage} disabled={!(createdOrder && createdOrder > 0) ? true : false} color="primary" style={{ marginTop: "20px", marginLeft: "20px" }} variant="outlined" >Autre inscription</Button> */}

                                            </Grid>
                                        </Grid>}
                                        {paymentChoice.includes('virement') && <Grid container spacing={2} md={12}>
                                            <Grid item md={12} xs={12}>
                                                <Typography align="left">
                                                    <ul>
                                                        <li>Envoyez votre virement d'un montant de {new Intl.NumberFormat("fr-FR", {

                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0,
                                                            style: "currency",
                                                            currency: "EUR",
                                                        }).format(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0) * getConfigurationdepositpercent())} en respectant ce montant précis, à KDC, IBAN : FR76 1240 6000 0900 1331 1240 430.
                                                        </li>
                                                        <li>Précisez : votre nom et votre prénom, les dates et le nom du stage.
                                                        </li>
                                                        <li>Vous devez effectuer votre virement sous 15 jours à compter d’aujourd’hui. Au-delà, nous annulerons votre réservation.
                                                        </li>
                                                    </ul>
                                                    Nous vous confirmerons votre inscription par email.<br />
                                                    Cliquez sur le bouton 'Envoyer l'inscription'.

                                                </Typography>
                                                <Button onClick={submitForm} disabled={isRegistrationValidated !== true || (createdOrder && createdOrder > 0) ? true : false} color="primary" style={{ marginTop: "20px" }} variant="outlined" >Envoyer l'inscription</Button>
                                                {/* <Button onClick={refreshPage} disabled={!(createdOrder && createdOrder > 0) ? true : false} color="primary" style={{ marginTop: "20px", marginLeft: "20px" }} variant="outlined" >Autre inscription</Button> */}
                                            </Grid>
                                        </Grid>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card >
            <h3 id="cgvanchor"></h3>

            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={captchaopen}
                aria-labelledby="form-dialog-title"
                // maxWidth="md"
                fullWidth
            > <DialogTitle id="form-dialog-title">
                    Valider votre commande
                </DialogTitle>
                <DialogContent>
                    <Grid container item md={12} xs={12}
                        direction="row-reverse"
                        justify="center"
                        alignItems="center" spacing={2} style={{ marginTop: "0px" }}>

                        <Grid item md={12} xs={12}>
                            <ReCAPTCHA
                                sitekey={configuration.recaptchasitekey}
                                onChange={onCaptchaChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Divider />
                    <Button onClick={handleCaptchaCancel} color="primary">Annuler</Button>
                </DialogActions>

            </Dialog>

        </div >
    );

}
{/* <Grid item md={9} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    helperText=""
                                                    label="Adresse"
                                                    margin="dense"
                                                    name="address"
                                                    onChange={handleInputChange}
                                                    required
                                                    value={address}
                                                //  variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item md={3} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    helperText=""
                                                    label="CP"
                                                    margin="dense"
                                                    name="zip"
                                                    onChange={handleInputChange}
                                                    required
                                                    value={zip}
                                                // variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    helperText=""
                                                    label="Ville"
                                                    margin="dense"
                                                    name="town"
                                                    onChange={handleInputChange}
                                                    required
                                                    value={town}
                                                // variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item md={3} xs={2} className={classes.blockCivility}>
                                                <Typography align="left" className={classes.blockCivility}>
                                                    {
                                                        <Select selectedIndex={country_id} name="country_id"
                                                            value={country_id} onChange={handleInputChange}
                                                            style={{ marginTop: "21px" }}
                                                            fullWidth
                                                            helperText="Pays"
                                                            label="Pays">
                                                            {countries.map(country =>
                                                                <MenuItem value={country.id} key={country.id}>{country.label}</MenuItem>
                                                            )}
                                                        </Select>}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={3} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    helperText=""
                                                    label="Téléphone"
                                                    margin="dense"
                                                    name="phone"
                                                    onChange={handleInputChange}
                                                    required
                                                    value={phone}
                                                />
                                            </Grid> */}