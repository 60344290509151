import React from "react";

import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper"

import Moment from "react-moment";
// *** Redux
import { shallowEqual, useSelector } from 'react-redux';

// *** Zopa components
// *** Styling

export default function OrderContent(props) {

    // *** Redux state
    const loccomputedOrderlines = useSelector(state => state.registrationReducer.computedOrderlines, shallowEqual);
    const isRegistrationValidated = useSelector(state => state.registrationReducer.isRegistrationValidated, shallowEqual);

    return (
        <div style={{ overflow: 'auto' }}>
            {isRegistrationValidated &&
                <TableContainer component={Paper} style={{ marginTop: "0.5em" }}>
                    <Table aria-label="simple table" size="small" >
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Produit</TableCell>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Qté</TableCell>
                                <TableCell align="left">Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loccomputedOrderlines &&
                                loccomputedOrderlines.map((loccomputedOrderline, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left">
                                            <Typography > {loccomputedOrderline.label}</Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            {loccomputedOrderline.array_options.options_lin_datedebut && <Typography >
                                                <Moment format="DD/MM/YYYY">
                                                    {new Date(loccomputedOrderline.array_options.options_lin_datedebut * 1000)}
                                                </Moment>
                                            </Typography>
                                            }
                                        </TableCell>
                                        <TableCell align="left">
                                            {<Typography >
                                                {loccomputedOrderline.qty}
                                            </Typography>
                                            }
                                        </TableCell>

                                        <TableCell align="left">{new Intl.NumberFormat("fr-FR", {
                                            style: "currency",
                                            currency: "EUR",
                                        }).format(loccomputedOrderline.total_ttc)
                                        }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            <TableRow>
                                <TableCell size="small"></TableCell>
                                <TableCell></TableCell>

                                <TableCell align="left">Total : </TableCell>
                                <TableCell align="left">{new Intl.NumberFormat("fr-FR", {
                                    style: "currency",
                                    currency: "EUR",
                                }).format(loccomputedOrderlines.reduce((a, b) => a + parseFloat(b.total_ttc), 0))
                                }</TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>}
        </div>
    );
}

