//import {
//  CONST_APIURLKUSALA,
//} from "../../Constants.js";
//import axios from "axios";
//import { store } from "../../App.js";
//import { getUserToken } from "../Login/LoginActions";
//import { getProductFromId } from "../CommonProduct/CommonProductActions"
// import { getCustomer } from "./CustomerActions"
import { getConfigurationValue } from "../GlobalLists/GlobalListsActions";



/****************************************************************************************** */
// *** Needs engine   **************************************************/


/**
  * When the user choose a course, we have to prepare the display of the course checkboses, all course participation ... 
  * Build the registration data structure
  * @param {*} productTemp
  * @param (*) checkDefaultState : the ckeckboxes default state (true or false).
  * @return : 
  */
export function computeChosenCourse(productTemp, checkDefaultState) {
  // 
  // setlocalError("");
  let registration = {};
  registration.product = productTemp;
  registration.mealType = "";
  registration.hostingType = "";

  // *** Compute course days number
  registration.datedebutStr = productTemp.array_options['options_sta_datedebut'];
  registration.datefinStr = productTemp.array_options['options_sta_datefin'];
  registration.datedebut = new Date(registration.datedebutStr * 1000);
  registration.datefin = new Date(registration.datefinStr * 1000);
  registration.sta_hostingclosed = productTemp.array_options['options_sta_hostingclosed'];
  if (registration.datedebutStr !== '' && registration.datefinStr !== '') {
    registration.nbJoursStage = Math.floor((((registration.datefin).getTime() - (registration.datedebut).getTime()) / (1000 * 3600 * 24)) + 1 + 2);
  } else {
    throw Error("Dates de stage invalides")
  }

  registration.datedebut = new Date(registration.datedebut.getTime() - (1000 * 3600 * 24));
  registration.datefin = new Date(registration.datefin.getTime() + (1000 * 3600 * 24));

  // *** Compute day's dates
  registration.tabBusyLigneColonne = new Array(registration.nbJoursStage);
  for (let ligne = 0; ligne < registration.nbJoursStage; ++ligne) {
    let datedebutemp = new Date(registration.datedebut);
    registration.tabBusyLigneColonne[ligne] = new Date(datedebutemp.setDate(registration.datedebut.getDate() + ligne)).getTime();
  }

  // *** participation
  registration.tabBusyParticipation = new Array(registration.nbJoursStage);
  registration.tabBusyParticipation.fill(true);
  registration.tabBusyParticipation[0] = null;
  registration.tabBusyParticipation[registration.tabBusyParticipation.length - 1] = null;

  // *** petit déjeuners
  registration.veggie = checkDefaultState;

  registration.tabBusyBreakfast = new Array(registration.nbJoursStage);
  registration.tabBusyBreakfast.fill(checkDefaultState);
  registration.tabBusyBreakfast[0] = null;


  // *** déjeuner
  registration.tabBusyLunch = new Array(registration.nbJoursStage);
  registration.tabBusyLunch.fill(checkDefaultState);
  registration.tabBusyLunch[0] = null;
  registration.tabBusyLunch[registration.tabBusyLunch.length - 1] = null;
  // *** diner
  registration.tabBusyDiner = new Array(registration.nbJoursStage);
  registration.tabBusyDiner.fill(checkDefaultState);
  registration.tabBusyDiner[registration.tabBusyDiner.length - 1] = null;

  // *** hosting
  // if (registration.sta_hostingclosed !== '1') {
  registration.tabBusyHosting = new Array(registration.nbJoursStage);
  registration.tabBusyHosting.fill(checkDefaultState);
  registration.tabBusyHosting[registration.tabBusyHosting.length - 1] = null;

  registration.hostingtype = -1;
  // }
  // *** Log registration object
  // console.log(JSON.stringify(registration));

  return registration;
  // 

}


/**
 * This is the main function : with the needs, the function calculate the orders lines
 * @param {*} registration 
 * @returns : an array of order lines
 */

export function computeRegistration(registration) {

  // *** Check configuration
  if (!getConfigurationValue('PMSMealDay')
    || !getConfigurationValue('PMMealDay')
    || !getConfigurationValue('PSMealDay')
    || !getConfigurationValue('MSMealDay')
    || !getConfigurationValue('PMealDay')
    || !getConfigurationValue('MMealDay')
    || !getConfigurationValue('SMealDay')
    || !getConfigurationValue('RefTaxes')) {
    throw Error("Erreur de configuration, impossible de trouver le fichier de configuration, contactez l'administrateur")
    return null;
  }
  let computedOrderlines = [];

  // *** participation  **************************************************
  let startItem = -1;
  registration.tabBusyParticipation.forEach(function (item, index, array) {
    if (item) {
      if (startItem === -1) {
        startItem = index;
      }
    } else {
      if (startItem > -1) {
        let newLine = {
          "fk_product": registration.product.id,
          "array_options": {
            "options_lin_datedebut": registration.tabBusyLigneColonne[startItem] / 1000,
            "options_lin_datefin": registration.tabBusyLigneColonne[index - 1] / 1000,
            "options_lin_room": null, "options_roomid": "0"
          },
          "qty": index - startItem,
          "subprice": registration.product.multiprices_ttc[1]
        }
        computedOrderlines.push(newLine);
        startItem = -1;
      }
    }
  });
  if (startItem > -1) {
    let newLine = {
      "fk_product": registration.product.id,
      "array_options": {
        "options_lin_datedebut": registration.tabBusyLigneColonne[startItem] / 1000,
        "options_lin_datefin": registration.tabBusyLigneColonne[registration.tabBusyParticipation.length - 1] / 1000,
        "options_lin_room": null, "options_roomid": "0"
      },
      "qty": registration.tabBusyParticipation.length - startItem,
      "subprice": registration.product.multiprices_ttc[1]
    }
    computedOrderlines.push(newLine);
  }

  // *** Repas **************************************************
  registration.tabBusyLigneColonne.forEach(function (item, index, array) {
    // *** petit déjeuners
    if (registration.tabBusyBreakfast[index] && registration.tabBusyLunch[index] && registration.tabBusyDiner[index]) {
      computedOrderlines.push({
        "fk_product": getConfigurationValue('PMSMealDay'), // 371',
        "array_options": {
          "options_lin_datedebut": registration.tabBusyLigneColonne[index] / 1000,
          "options_lin_datefin": registration.tabBusyLigneColonne[index] / 1000,
          "options_lin_room": registration.mealType, "options_roomid": "0"
        },
        "qty": 1
      });

    } else if (registration.tabBusyBreakfast[index] && registration.tabBusyLunch[index] && !registration.tabBusyDiner[index]) {

      computedOrderlines.push({
        "fk_product": getConfigurationValue('PMMealDay'), // '369',
        "array_options": {
          "options_lin_datedebut": registration.tabBusyLigneColonne[index] / 1000,
          "options_lin_datefin": registration.tabBusyLigneColonne[index] / 1000,
          "options_lin_room": registration.mealType, "options_roomid": "0"
        },
        "qty": 1
      });


    } else if (registration.tabBusyBreakfast[index] && !registration.tabBusyLunch[index] && registration.tabBusyDiner[index]) {
      computedOrderlines.push({
        "fk_product": getConfigurationValue('PSMealDay'), // '370',
        "array_options": {
          "options_lin_datedebut": registration.tabBusyLigneColonne[index] / 1000,
          "options_lin_datefin": registration.tabBusyLigneColonne[index] / 1000,
          "options_lin_room": registration.mealType, "options_roomid": "0"
        },
        "qty": 1
      });

    } else if (!registration.tabBusyBreakfast[index] && registration.tabBusyLunch[index] && registration.tabBusyDiner[index]) {
      computedOrderlines.push({
        "fk_product": getConfigurationValue('MSMealDay'),//'368',
        "array_options": {
          "options_lin_datedebut": registration.tabBusyLigneColonne[index] / 1000,
          "options_lin_datefin": registration.tabBusyLigneColonne[index] / 1000,
          "options_lin_room": registration.mealType, "options_roomid": "0"
        },
        "qty": 1
      });


    } else if (registration.tabBusyBreakfast[index] && !registration.tabBusyLunch[index] && !registration.tabBusyDiner[index]) {
      computedOrderlines.push({
        "fk_product": getConfigurationValue('PMealDay'),//'366',
        "array_options": {
          "options_lin_datedebut": registration.tabBusyLigneColonne[index] / 1000,
          "options_lin_datefin": registration.tabBusyLigneColonne[index] / 1000,
          "options_lin_room": registration.mealType, "options_roomid": "0"
        },
        "qty": 1
      });

    } else if (!registration.tabBusyBreakfast[index] && registration.tabBusyLunch[index] && !registration.tabBusyDiner[index]) {
      computedOrderlines.push({
        "fk_product": getConfigurationValue('MMealDay'),//'365',
        "array_options": {
          "options_lin_datedebut": registration.tabBusyLigneColonne[index] / 1000,
          "options_lin_datefin": registration.tabBusyLigneColonne[index] / 1000,
          "options_lin_room": registration.mealType, "options_roomid": "0"
        },
        "qty": 1
      });

    } else if (!registration.tabBusyBreakfast[index] && !registration.tabBusyLunch[index] && registration.tabBusyDiner[index]) {
      computedOrderlines.push({
        "fk_product": getConfigurationValue('SMealDay'),//'367',
        "array_options": {
          "options_lin_datedebut": registration.tabBusyLigneColonne[index] / 1000,
          "options_lin_datefin": registration.tabBusyLigneColonne[index] / 1000,
          "options_lin_room": registration.mealType, "options_roomid": "0"
        },
        "qty": 1
      });

    }
  });

  // *** Hébergement **************************************************
  let startItemHosting = -1;
  if (registration.tabBusyHosting) {
    registration.tabBusyHosting.forEach(function (item, index, array) {
      if (item) {
        if (startItemHosting === -1) {
          startItemHosting = index;
        }
      } else {
        if (startItemHosting > -1) {
          // *** Insert hosting
          computedOrderlines.push({
            "fk_product": registration.hostingType,
            "array_options": {
              "options_lin_datedebut": registration.tabBusyLigneColonne[startItemHosting] / 1000,
              "options_lin_datefin": registration.tabBusyLigneColonne[index - 1] / 1000,
              "options_lin_room": null, "options_roomid": "0"
            },
            "qty": index - startItemHosting
          });


          // *** Insert Taxes
          computedOrderlines.push({
            "fk_product": getConfigurationValue('RefTaxes'),// '372',
            "array_options": {
              "options_lin_datedebut": registration.tabBusyLigneColonne[startItemHosting] / 1000,
              "options_lin_datefin": registration.tabBusyLigneColonne[index - 1] / 1000,
              "options_lin_room": null, "options_roomid": "0"
            },
            "qty": index - startItemHosting
          });

          startItemHosting = -1;
        }
      }

    });
    if (startItemHosting > -1) {
      // *** Insert hosting
      computedOrderlines.push({
        "fk_product": registration.hostingType,
        "array_options": {
          "options_lin_datedebut": registration.tabBusyLigneColonne[startItemHosting] / 1000,
          "options_lin_datefin": registration.tabBusyLigneColonne[registration.tabBusyParticipation.length - 1] / 1000,
        },
        "qty": registration.tabBusyParticipation.length - startItemHosting
      });


      // *** Insert Taxes
      computedOrderlines.push({
        "fk_product": getConfigurationValue('RefTaxes'),// '372',
        "array_options": {
          "options_lin_datedebut": registration.tabBusyLigneColonne[startItemHosting] / 1000,
          "options_lin_datefin": registration.tabBusyLigneColonne[registration.tabBusyParticipation.length - 1] / 1000,
        },
        "qty": registration.tabBusyParticipation.length - startItemHosting
      });
    }
  }

  return computedOrderlines;
}