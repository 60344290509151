import {
  GET_RESTAURANTBOOKING_BEGIN,
  GET_RESTAURANTBOOKING_SUCCESS,
  GET_RESTAURANTBOOKING_FAILURE,

  SET_RESTAURANTBOOKING_INIT,

  PUT_MAXMEALSFORADAY_BEGIN,
  PUT_MAXMEALSFORADAY_SUCCESS,
  PUT_MAXMEALSFORADAY_FAILURE
} from "./RestaurantActions";

const initialState = {
  bookingLines: [],
  tabFullResult: [],
  tabFullAggregatedResult: [],
  maxMealsForADayValues: [],
  loading: false,
  error: null,
};

export default function RestaurantReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RESTAURANTBOOKING_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        bookingLines: [],
        tabFullResult: [],
        tabFullAggregatedResult: []
      };

    case GET_RESTAURANTBOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        bookingLines: action.payload.bookingLines,
        tabFullResult: action.payload.tabFullResult,
        tabFullAggregatedResult: action.payload.tabFullAggregatedResult
      };

    case GET_RESTAURANTBOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        bookingLines: [],
        tabFullResult: [],
        tabFullAggregatedResult: []
      };

    case SET_RESTAURANTBOOKING_INIT:
      return {
        ...state,
        loading: false,
        error: null,
        bookingLines: [],
        tabFullResult: [],
        tabFullAggregatedResult: []
      };

    case PUT_MAXMEALSFORADAY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        maxMealsForADayValues: []
      };

    case PUT_MAXMEALSFORADAY_SUCCESS:
      return {
        ...state,
        loading: false,
        maxMealsForADayValues: action.payload.maxMealsForADayValues
      };

    case PUT_MAXMEALSFORADAY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        maxMealsForADayValues: []
      };

    // case PUT_MAXMEALSFORADAY_INIT:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: null,
    //     maxMealsForADayValues: []
    //   };
    default:
      return state;
  }
}
