import React from 'react';
import logo from './logo.svg';
import './App.css';
// import ApplyForm from "./views/ApplyForm/ApplyForm"
import { ConfirmProvider } from 'material-ui-confirm';
// *** Router
// import { BrowserRouter as Router } from "react-router-dom";
// import Routes from "./Routes.js";
// import { createBrowserHistory } from "history";
// import { Switch, Redirect, Route } from "react-router-dom";

// Theming
import "./App.css";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/styles";

import { Provider } from "react-redux";
import thunk from "redux-thunk";

// *** Redux Initialisation, store building
import { createStore, applyMiddleware, combineReducers } from "redux";
import productReducer from "./services/services/ProductReducer";
import restaurantReducer from "./services/services/RestaurantReducer";
import commonProductReducer from "./services/CommonProduct/CommonProductReducer"
import globalListsReducer from "./services/GlobalLists/GlobalListsReducer";
// import orderSearchReducer from "./services/services/OrderSearchReducer";
import registrationReducer from "./views/ApplyForm/RegistrationReducer"

// *** zopaweb components
import PayplugReturnOk from "./views/ApplyForm/PayplugReturnOk"
import ApplyForm from "./views/ApplyForm/ApplyForm"
import PayplugReturnNok from "./views/ApplyForm/PayplugReturnNok"
import PayplugReturnokorder from "./views/ApplyForm/PayplugReturnokorder"

const rootReducer = combineReducers({
  // orderSearchReducer: orderSearchReducer,
  productReducer: productReducer,
  globalListsReducer: globalListsReducer,
  commonProductReducer: commonProductReducer,
  restaurantReducer: restaurantReducer,

  registrationReducer: registrationReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));


function App(props) {
  const term = props;
  const url = window.location.search;
  return (
    <div className="App" >
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ConfirmProvider>
            {url && url.length > 0 && url.includes('retour=ok')
              ? <PayplugReturnOk /> : url.includes('retour=nok')
                ? <PayplugReturnNok /> : url.includes('retour=order')
                  ? <PayplugReturnokorder /> : <ApplyForm />}
          </ConfirmProvider>
        </Provider>
      </ThemeProvider>
    </div >

  );
}

export default App;