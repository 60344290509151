import React from "react";

import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { Card, CardContent } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Autocomplete from "@material-ui/lab/Autocomplete"
import FormHelperText from "@material-ui/core/FormHelperText"
import Grid from '@material-ui/core/Grid';

// *** Redux
import { useDispatch } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux';
import { useState, useEffect } from "react"

// *** Zopa components
import { getSubscriptionProducts } from "../../services/CommonProduct/CommonProductActions"
// import { getCountriesTable } from "../../services/GlobalLists/GlobalListsActions"
import { setReduxinputsChanged, setReduxCustomer, setIsRegistrationValidated } from "./RegistrationActions"

// *** Styling
import clsx from "clsx";
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
    root: {
        background: "linear-gradient(45deg, #F4F6F6 30%, #F2F3F4 90%)",
        padding: theme.spacing(2),
    },
    pageTitle: {
        color: "#6b6c6d"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    blockCardHeaderStyle:
        theme.palette.blockCardHeaderStyle,
    blockCardHeaderIcon:
        theme.palette.blockCardHeaderIcon

}));
export default function IdentityForm(props) {
    const { ...rest } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    // *** Redux state
    const civilities = useSelector(state => state.globalListsReducer.civilities, shallowEqual);
    // const products = useSelector(state => state.commonProductReducer.products, shallowEqual);
    const incomelevels = useSelector(state => state.globalListsReducer.incomelevels, shallowEqual);
    const subscriptionProducts = getSubscriptionProducts();
    const [loccustomer, setLoccustomer] = useState({});
    const reduxcustomer = useSelector(state => state.registrationReducer.loccustomer, shallowEqual);
    const [rerender, setRerender] = React.useState(false);

    // useEffect(() => {
    //     // 
    // }, []);

    function handleChange(event) {

        if (event.target) {
            setLoccustomer((prevState) => {
                if (event.target.name === 'firstname')
                    prevState.firstname = event.target.value;
                if (event.target.name === 'lastname')
                    prevState.lastname = event.target.value;
                if (event.target.name === 'email')
                    prevState.email = event.target.value;
                if ((event.target.name === "civility"))
                    prevState.civility = event.target.value;
                if ((event.target.name === "emailbis"))
                    prevState.emailbis = event.target.value;
                if ((event.target.name === "price_level"))
                    prevState.price_level = event.target.value;
                // if ((event.target.name === "price_level"))
                //     prevState.price_level = event.target.value;
                return prevState
            });

            dispatch(setReduxCustomer(loccustomer));
            dispatch(setIsRegistrationValidated(false));
            dispatch(setReduxinputsChanged(true));
        }
        setRerender(!rerender);
    }

    /**
     * Change the subscription card choice
     * @param {*} newValue 
     */
    function setsubsProductId(newValue) {
        setLoccustomer((prevState) => {
            prevState.subsproductId = newValue;
            return prevState
        });
        dispatch(setReduxCustomer(loccustomer));
        dispatch(setIsRegistrationValidated(false));
        dispatch(setReduxinputsChanged(true));
    }
    /**
      * Change the subscription card choice
      * @param {*} newValue 
      */
    function setIncomeLevel(newValue) {

        setLoccustomer((prevState) => {
            if (newValue === null)
                prevState.price_level = "";
            else
                prevState.price_level = newValue.code;
            return prevState
        });
        dispatch(setReduxCustomer(loccustomer));
        dispatch(setIsRegistrationValidated(false));
        dispatch(setReduxinputsChanged(true));

    }

    return (

        <Card style={{ margin: "10px" }}>
            <CardHeader title="2 - Communiquez-nous votre identité" subheader="" style={{ backgroundColor: "#DFDFDB" }}>
            </CardHeader>
            <CardContent>
                {/* <form className={classes.root} noValidate autoComplete="off"> */}
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item md={1} xs={2}>
                        {civilities &&
                            <Select label="Civilité" selectedIndex={reduxcustomer.civility} name="civility"
                                value={reduxcustomer.civility} onChange={handleChange} style={{ marginTop: "21px" }}
                            >
                                {civilities.map(civility =>
                                    <MenuItem value={civility.rowid} key={civility.rowid}>{civility.code}</MenuItem>
                                )}
                            </Select>
                        }
                        <FormHelperText>Civilité</FormHelperText>
                    </Grid>
                    <Grid item md={5} xs={10}>
                        <TextField
                            fullWidth
                            label="Nom"
                            margin="dense"
                            name="lastname"
                            onChange={handleChange}
                            required
                            value={reduxcustomer.lastname}
                            style={{ marginLeft: "5px" }}
                            helperText="Nom"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label="Prénom"
                            margin="dense"
                            name="firstname"
                            onChange={handleChange}
                            required
                            value={reduxcustomer.firstname}
                            style={{ marginLeft: "5px" }}
                            helperText="Prénom"
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label="email"
                            margin="dense"
                            type="email"
                            name="email"
                            onChange={handleChange}
                            value={reduxcustomer.email}
                            required
                            helperText="L’email doit être propre à la personne qui s’inscrit"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label="Confirmer email"
                            margin="dense"
                            type="email"
                            name="emailbis"
                            onChange={handleChange}
                            value={reduxcustomer.emailbis}
                            required
                            helperText="Veuillez confirmer votre email"
                        />
                    </Grid>

                </Grid>
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Autocomplete
                            id="subsproductId"
                            options={incomelevels}
                            getOptionLabel={(incomelevel) => incomelevel.label}
                            style={{ marginTop: "20" }}
                            name="subsproductId"
                            onChange={(event, newValue) => {
                                setIncomeLevel(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Niveau de revenu"
                                    name="test2"
                                    fullWidth
                                    helperText="Un barème adapté aux revenus mensuels de chacun permet l’accueil du plus grand nombre"
                                />
                            )}
                        />
                        {/* {<Select required selectedIndex={reduxcustomer.price_level}
                            name="price_level" value={reduxcustomer.price_level} onChange={handleChange}
                            style={{ marginTop: "20px" }}
                            fullWidth
                            label="Choisir une carte d'adhésion"
                        >
                            {incomelevels.map(incomelevel =>
                                <MenuItem value={incomelevel.code} key={incomelevel.code}>{incomelevel.label}</MenuItem>
                            )}
                        </Select>} */}

                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Autocomplete
                            id="subsproductId"
                            options={subscriptionProducts}
                            getOptionLabel={(option) => option.label}
                            style={{ marginTop: "20" }}
                            name="subsproductId"
                            onChange={(event, newValue) => {
                                setsubsProductId(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Carte d'adhésion"
                                    name="test2"
                                    fullWidth
                                    helperText="Voulez-vous renouveller votre carte d'adhésion ?"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    )
};
